<template>
  <div class="newslist">
    <myHeader title="北斗资讯" sTitle="Beidou Consulting" />
    <template v-for="item in newlistData">
      <NewListItem v-bind="item" :key="item.id"/>
    </template>
  </div>
</template>
<script>
import myHeader from "@/components/MyHeader.vue"
import NewListItem from "@/components/NewListItem"
import newlistjson from "@/assets/json/newslistData.json"
export default {
  name:'newlist',
  components:{
    myHeader,NewListItem
  },
  data() {
    return {
      newlistData:newlistjson
    }
  },
  mounted(){
    console.log(this.newlistData);
  },
  methods:{
    
  }
}
</script>