<template>
  <div class="news-list-item">
    <!-- 左侧图片，使用背景，是为了保证图片居中裁剪~@/assets/img/news.png -->
    <div class="img" :style="{backgroundImage:`url(${getImg(imgName)})`}"></div>
    <!-- 内容 -->
    <div class="cont">
      <span class="title">{{title}}</span>
      <span class="s-title">{{sTitle}}</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  // 副标题颜色
  $small-tit-color:#a1a1a1;
  .news-list-item {
    display:flex;
    align-items:center;
    justify-content:space-around;
    padding: 3vw 2vw;
    .img {
      flex: 1;
      width: 20vw;
      height: 20vw;
      background-size: cover;
      // background-position: center;
    }
    .cont {
      flex: 4;
      display:flex;
      flex-direction:column;
      margin-left: 3vw;
      span {
        text-align: left;
      }
      .title {
        font-size: 4vw;
        color: #000;
        font-weight: bold;
      }
      .s-title {
        font-size: 3.5vw;
        color: $small-tit-color;
        margin: 1.5vw 0;
      }
    }
  }
</style>
<script>
export default {
  name: "newlistitem",
  props: ['imgName','title','sTitle'],
  methods:{
    getImg(imgName){
      try {
        return require(`@/assets/img/${imgName}`);
      }catch (e) {
        return require(`@/assets/img/news.png`);
      }
    }
  }
}
</script>
