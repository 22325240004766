<template>
  <div class="my-header">
    <!-- 顶部logo -->
    <i class="logo"></i>
    <!-- 标题，文字可替换 -->
    <span class="title">
      <i class="leftImg"></i>
      <i>{{title}}</i>
      <i class="rightImg"></i>
    </span>
    <!-- 副标题 -->
    <span class="stitle">{{sTitle}}</span>
    <!-- 分割线 -->
    <div class="line"></div>
  </div>
</template>
<style lang="scss" scoped>
  // 副标题颜色
  $small-tit-color:#a1a1a1;
  @mixin img-set {
    display: block;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .my-header {
    // 顶部logo
    .logo {
      @include img-set;
      width: 100%;
      height: 30vw;
      background-image: url("~@/assets/img/logo.png");
    }
    // 标题
    .title {
      display:flex;
      align-items: center;
      .leftImg {
        @include img-set;
        width: 1.5vw;
        height: 5vw;
        background-image: url("~@/assets/img/tit1.png");
        margin-right: 2vw;
        margin-top: 1vw;
      }
      i {
        font-style: normal;
        font-weight: bold;
        font-size: 5vw;
        color: #000;
      }
      .rightImg {
        @include img-set;
        width: 7vw;
        height: 3.5vw;
        background-image: url("~@/assets/img/tit2.png");
        margin-left: 2vw;
      }
    }
    // 副标题
    .stitle {
      font-size: 3.5vw;
      color: $small-tit-color;
      text-align: left;
      display: flex;
      margin-left: 3.5vw;
    }
    // 分割线
    .line {
      height:2vw;
      margin-top: 2vw;
      background-color: #f5f6f7;
    }
  }
</style>
<script>
export default {
  name:"myHeader",
  props: ['title','sTitle']
}
</script>
